import React from 'react';
import { Link } from 'react-router-dom';

function Bannerone() {
  return (
    <>
      <div className="sec-circle fl-wrap" />
      {/*section */}
      <section  className="gray-bg hidden-section particles-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>Explore Best Cities</h2>
            <div className="section-subtitle">Catalog of Categories</div>
            <span className="section-separator" />
            <p>
              In ut odio libero, at vulputate urna. Nulla tristique mi a massa
              convallis cursus.
            </p>
          </div>
          <div className="listing-item-grid_container fl-wrap">
            <div className="row">
              {/*  listing-item-grid  */}
              <div className="col-sm-4">
                <div className="listing-item-grid" style={{ backgroundImage: "url('images/all/56.jpg')" }}>
                  <div className="d-gr-sec" />
                  <div className="listing-counter color2-bg">
                    <span>16 </span> Locations
                  </div>
                  <div className="listing-item-grid_title">
                    <h3>
                      <Link  to="listing.html">New York</Link> 
                    </h3>
                    <p>
                      Constant care and attention to the patients makes good record
                    </p>
                  </div>
                </div>
              </div>
              {/*  listing-item-grid end  */}
              {/*  listing-item-grid  */}
              <div className="col-sm-4">
                <div className="listing-item-grid" style={{ backgroundImage: "url('images/all/57.jpg')" }}>
                  <div className="d-gr-sec" />
                  <div className="listing-counter color2-bg">
                    <span>22 </span> Locations
                  </div>
                  <div className="listing-item-grid_title">
                    <h3>
                      <Link  to="listing.html">Paris</Link> 
                    </h3>
                    <p>
                      Constant care and attention to the patients makes good record
                    </p>
                  </div>
                </div>
              </div>
              {/*  listing-item-grid end  */}
              {/*  listing-item-grid  */}
              <div className="col-sm-4">
                <div className="listing-item-grid" style={{ backgroundImage: "url('images/all/58.jpg')" }}>
                  <div className="d-gr-sec" />
                  <div className="listing-counter color2-bg">
                    <span>9 </span> Locations
                  </div>
                  <div className="listing-item-grid_title">
                    <h3>
                      <Link  to="listing.html">Moscow</Link> 
                    </h3>
                    <p>
                      Constant care and attention to the patients makes good record
                    </p>
                  </div>
                </div>
              </div>
              {/*  listing-item-grid end  */}
              {/*  listing-item-grid  */}
              <div className="col-sm-4">
                <div className="listing-item-grid" style={{ backgroundImage: "url('images/all/60.jpg')" }}>
                  <div className="d-gr-sec" />
                  <div className="listing-counter color2-bg">
                    <span>12 </span> Locations
                  </div>
                  <div className="listing-item-grid_title">
                    <h3>
                      <Link  to="listing.html">Rome</Link> 
                    </h3>
                    <p>
                      Constant care and attention to the patients makes good record
                    </p>
                  </div>
                </div>
              </div>
              {/*  listing-item-grid end  */}
              {/*  listing-item-grid  */}
              <div className="col-sm-8">
                <div className="listing-item-grid" style={{ backgroundImage: "url('images/all/59.jpg')" }}>
                  <div className="d-gr-sec" />
                  <div className="listing-counter color2-bg">
                    <span>33 </span> Locations
                  </div>
                  <div className="listing-item-grid_title">
                    <h3>
                      <Link  to="listing.html">London</Link> 
                    </h3>
                    <p>
                      Constant care and attention to the patients makes good record
                    </p>
                  </div>
                </div>
              </div>
              {/*  listing-item-grid end  */}
            </div>
          </div>
          <Link  to="listing.html" className="btn dec_btn   color2-bg">
            View All Cities
            <i className="fal fa-arrow-alt-right" />
          </Link> 
        </div>
      </section>
    </>
  );
}

export default Bannerone;
