import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../context/ContextFile";
import Swal from "sweetalert2";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { fetchData } from "./HeroSection";

function New({ data }) {
  const [datas, setDatas] = useState([]);
  const handleFav = async (id) => {
  
    const formData = new FormData();
    formData.append("listing_id", id);
    try {
      const response = await axios.post(
        `${BASE_URL}/add-to-favourite`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchData().then((res) => {
        setDatas(res.data?.data);
      });

      Swal.fire( response?.data?.message);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setDatas(data);
  }, [data]);

  let { theme } = useContext(ThemeContext);

  // console.log(theme,"data?");
  if (theme.length === 0) {
    console.log("hi");
  } else if (theme.response?.data?.status === false) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Your Location Not Found",
    });
  } else {
    Swal.fire({
      icon: "success",
      title: "Your Location Found",
    });
  }

  return (
    <>
      {/* <h1>{theme.response.data.status === false ? "Not found" : "found"}</h1> */}
      {theme?.length === 0
        ? datas?.map((e) => {
            return (
              <div className="gallery-item hotels col-md-3">
                {/* listing-item  */}
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap">
                    <div className="geodir-category-img">
                      <div
                        onClick={() => handleFav(e.id)}
                        className={
                          e?.is_favourite === 1
                            ? `geodir-js-favorite_btn1`
                            : `geodir-js-favorite_btn`
                        }
                      >
                        <i className="fal fa-heart" />
                        <span>Save</span>``
                      </div>
                      <Link
                        to={`/details/${e.id}`}
                        className="geodir-category-img-wrap fl-wrap"
                      >
                        <img
                          style={{ width: "300px", height: "200px" }}
                          src={e?.listing_images[0]?.image}
                          alt=""
                        />
                      </Link>
                      <div className="listing-avatar">
                        <Link to={`/details/${e.id}`}>
                          <img src="images/avatar/3.jpg" alt="" />
                        </Link>
                        <span className="avatar-tooltip">
                          Added By <strong>Kliff Antony</strong>
                        </span>
                      </div>
                      <div className="geodir_status_date gsd_open">
                        <i className="fal fa-lock-open" />
                        Open Now
                      </div>
                      <div className="geodir-category-opt">
                        <div className="listing-rating-count-wrap">
                          <div className="review-score">5.0</div>
                          <div
                            className="listing-rating card-popup-rainingvis"
                            data-starrating2={5}
                          />
                          <br />
                          <div className="reviews-count"></div>
                        </div>
                      </div>
                    </div>
                    <div className="geodir-category-content fl-wrap title-sin_item">
                      <div className="geodir-category-content-title fl-wrap">
                        <div className="geodir-category-content-title-item">
                          <h3 className="title-sin_map">
                            <Link to={`/details/${e.id}`}>
                              {e?.host?.first_name}
                            </Link>
                            <span className="verified-badge">
                              <i className="fal fa-check" />
                            </span>
                          </h3>
                          <div className="geodir-category-location fl-wrap">
                            <Link to={`/details/${e.id}`}>
                              <i className="fas fa-map-marker-alt" />{" "}
                              {e?.address}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="geodir-category-text fl-wrap">
                        <p className="small-text">{e?.house_rule_addi_info}</p>
                        <div className="facilities-list fl-wrap">
                          <div className="facilities-list-title">
                            Facilities :{" "}
                          </div>
                          <ul className="no-list-style">
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Free WiFi"
                            >
                              <i className="fal fa-wifi" />
                            </li>
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Parking"
                            >
                              <i className="fal fa-parking" />
                            </li>
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Non-smoking Rooms"
                            >
                              <i className="fal fa-smoking-ban" />
                            </li>
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Pets Friendly"
                            >
                              <i className="fal fa-dog-leashed" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="geodir-category-footer fl-wrap">
                        <Link className="listing-item-category-wrap">
                          <div className="listing-item-category  yellow-bg">
                            <i className="fal fa-bed" />
                          </div>
                          <span>{e?.type_of_space}</span>
                        </Link>
                        <div className="geodir-opt-list">
                          <ul className="no-list-style">
                            <li>
                              <Link
                                to={`/details/${e.id}`}
                                className="show_gcc"
                              >
                                <i className="fal fa-envelope" />
                                <span className="geodir-opt-tooltip">
                                  Contact Info
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/details/${e.id}`}
                                className="single-map-item"
                                data-newlatitude="40.72228267"
                                data-newlongitude="-73.99246214"
                              >
                                <i className="fal fa-map-marker-alt" />
                                <span className="geodir-opt-tooltip">
                                  On the map <strong>4</strong>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <div
                                className="dynamic-gal gdop-list-link"
                                data-dynamicpath="[{'src': 'images/all/16.jpg'},{'src': 'images/all/27.jpg'}, {'src': 'images/all/20.jpg'}]"
                              >
                                <i className="fal fa-search-plus" />
                                <span className="geodir-opt-tooltip">
                                  Gallery
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="price-level geodir-category_price">
                          <span
                            className="price-level-item"
                            data-pricerating={4}
                          />
                          <span className="price-name-tooltip">Ultra High</span>
                        </div>
                        <div className="geodir-category_contacts">
                          <div className="close_gcc">
                            <i className="fal fa-times-circle" />
                          </div>
                          <ul className="no-list-style">
                            <li>
                              <span>
                                <i className="fal fa-phone" /> Call :{" "}
                              </span>
                              <Link to={`/details/${e.id}`}>+38099231212</Link>
                            </li>
                            <li>
                              <span>
                                <i className="fal fa-envelope" /> Write :{" "}
                              </span>
                              <Link to={`/details/${e.id}`}>
                                yourmail@domain.com
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                {/* listing-item end */}
              </div>
            );
          })
        : theme?.data?.listings.map((e) => {
            return (
              <div className="gallery-item hotels">
                {/* listing-item  */}
                <div className="listing-item">
                  <article className="geodir-category-listing fl-wrap">
                    <div className="geodir-category-img">
                      <div className="geodir-js-favorite_btn">
                        <i className="fal fa-heart" />
                        <span>Save</span>
                      </div>
                      <Link
                        to={`/details/${e.id}`}
                        className="geodir-category-img-wrap fl-wrap"
                      >
                        <img src={e?.listing_images[0]?.image} alt="" />
                      </Link>
                      <div className="listing-avatar">
                        <Link to={`/details/${e.id}`}>
                          <img src="images/avatar/3.jpg" alt="" />
                        </Link>
                        <span className="avatar-tooltip">
                          Added By <strong>Kliff Antony</strong>
                        </span>
                      </div>
                      <div className="geodir_status_date gsd_open">
                        <i className="fal fa-lock-open" />
                        Open Now
                      </div>
                      <div className="geodir-category-opt">
                        <div className="listing-rating-count-wrap">
                          <div className="review-score">5.0</div>
                          <div
                            className="listing-rating card-popup-rainingvis"
                            data-starrating2={5}
                          />
                          <br />
                          <div className="reviews-count"></div>
                        </div>
                      </div>
                    </div>
                    <div className="geodir-category-content fl-wrap title-sin_item">
                      <div className="geodir-category-content-title fl-wrap">
                        <div className="geodir-category-content-title-item">
                          <h3 className="title-sin_map">
                            <Link to={`/details/${e.id}`}>
                              {e?.host?.first_name}
                            </Link>
                            <span className="verified-badge">
                              <i className="fal fa-check" />
                            </span>
                          </h3>
                          <div className="geodir-category-location fl-wrap">
                            <Link to={`/details/${e.id}`}>
                              <i className="fas fa-map-marker-alt" />{" "}
                              {e?.address}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="geodir-category-text fl-wrap">
                        <p className="small-text">{e?.house_rule_addi_info}</p>
                        <div className="facilities-list fl-wrap">
                          <div className="facilities-list-title">
                            Facilities :{" "}
                          </div>
                          <ul className="no-list-style">
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Free WiFi"
                            >
                              <i className="fal fa-wifi" />
                            </li>
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Parking"
                            >
                              <i className="fal fa-parking" />
                            </li>
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Non-smoking Rooms"
                            >
                              <i className="fal fa-smoking-ban" />
                            </li>
                            <li
                              className="tolt"
                              data-microtip-position="top"
                              data-tooltip="Pets Friendly"
                            >
                              <i className="fal fa-dog-leashed" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="geodir-category-footer fl-wrap">
                        <Link className="listing-item-category-wrap">
                          <div className="listing-item-category  yellow-bg">
                            <i className="fal fa-bed" />
                          </div>
                          <span>{e?.type_of_space}</span>
                        </Link>
                        <div className="geodir-opt-list">
                          <ul className="no-list-style">
                            <li>
                              <Link
                                to={`/details/${e.id}`}
                                className="show_gcc"
                              >
                                <i className="fal fa-envelope" />
                                <span className="geodir-opt-tooltip">
                                  Contact Info
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/details/${e.id}`}
                                className="single-map-item"
                                data-newlatitude="40.72228267"
                                data-newlongitude="-73.99246214"
                              >
                                <i className="fal fa-map-marker-alt" />
                                <span className="geodir-opt-tooltip">
                                  On the map <strong>4</strong>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <div
                                className="dynamic-gal gdop-list-link"
                                data-dynamicpath="[{'src': 'images/all/16.jpg'},{'src': 'images/all/27.jpg'}, {'src': 'images/all/20.jpg'}]"
                              >
                                <i className="fal fa-search-plus" />
                                <span className="geodir-opt-tooltip">
                                  Gallery
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="price-level geodir-category_price">
                          <span
                            className="price-level-item"
                            data-pricerating={4}
                          />
                          <span className="price-name-tooltip">Ultra High</span>
                        </div>
                        <div className="geodir-category_contacts">
                          <div className="close_gcc">
                            <i className="fal fa-times-circle" />
                          </div>
                          <ul className="no-list-style">
                            <li>
                              <span>
                                <i className="fal fa-phone" /> Call :{" "}
                              </span>
                              <Link to={`/details/${e.id}`}>+38099231212</Link>
                            </li>
                            <li>
                              <span>
                                <i className="fal fa-envelope" /> Write :{" "}
                              </span>
                              <Link to={`/details/${e.id}`}>
                                yourmail@domain.com
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                {/* listing-item end */}
              </div>
            );
          })}

      {/* <LocationMap data={data}/> */}
    </>
  );
}

export default New;
