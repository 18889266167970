import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "./auth/Baseurl";

function BokkingR() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(2);   

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/booking-user`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log(response.data?.data);
        setData(response.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); 
  }, []); 

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="col-md-9">
      <div className="dashboard-title   fl-wrap">
        <h3>Bookings</h3>
      </div>
      {currentCards?.map((e) => (
        <div className="profile-edit-container fl-wrap block_box">
          {/* booking-list*/}
          <div className="booking-list">
            <div className="booking-list-message">
              <div className="booking-list-message-avatar">
                <img src={e?.user?.image} alt="" />
              </div>
              <span className="booking-list-new green-bg">New</span>
              <div className="booking-list-message-text">
                <h4>
                  {e?.first_name} - {e?.last_name}
                </h4>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Listing Item :</span>
                  <span className="booking-text">
                    <Link to="#">
                      {e?.listing?.listing_type}
                    </Link>
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Guest :</span>
                  <span className="booking-text">{e?.no_of_guest}</span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Booking Date :</span>
                  <span className="booking-text">
                    {e?.arrive_date} - {e?.depart_date}
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Mail :</span>
                  <span className="booking-text">
                    <Link to="#" target="_top">
                      {e?.user?.email}
                    </Link>
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Phone :</span>
                  <span className="booking-text">
                    <Link to="tel:+496170961709" target="_top">
                      {e?.phone}
                    </Link>
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Payment State :</span>
                  <span className="booking-text">
                    {" "}
                    <strong className="done-paid">Paid</strong> using Paypal
                  </span>
                </div>
                <span className="fw-separator" />
                <p>{e?.note}</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="pagination">
        <Link
          to="#"
          className="prevposts-link"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fas fa-caret-left" />
          <span>Prev</span>
        </Link>
        {Array.from({ length: Math.ceil(data.length / cardsPerPage) }).map(
          (item, index) => (
            <Link
              key={index}
              to="#"
              className={currentPage === index + 1 ? "current-page" : undefined}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Link>
          )
        )}
        <Link
          to="#"
          className="nextposts-link"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(data.length / cardsPerPage)}
        >
          <span>Next</span>
          <i className="fas fa-caret-right" />
        </Link>
      </div>
    </div>
  );
}

export default BokkingR;
