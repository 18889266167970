import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import Bannerone from "./Bannerone";
import Bannertwo from "./Bannertwo";
import Bannerthree from "./Bannerthree";
import Bannerfour from "./Bannerfour";
import LocationMap from "./map/LocationMap";
import BASE_URL from "./auth/Baseurl";
import axios from "axios";

export const fetchData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/listing-user`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

function HeroSection() {
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchData().then((res) => {
      setData(res.data?.data);
    });
  }, [token]);
  return (
    <div id="wrapper">
      {/* content*/}

      <LocationMap data={data} />
      <Bannerthree setData={setData} data={data} />
      <Banner />
      <Bannerone />
      <Bannertwo />
      <Bannerfour />
    </div>
  );
}

export default HeroSection;
