import React from 'react'
// import { Link } from 'react-router-dom'
import New from './New'

function Bannerthree({data,setData}) {
  // console.log(data,"value");


  return (
    <section>
  <div className="container big-container">
    
 
    <div className="grid-item-holder gallery-items fl-wrap">
      <div style={{display:"flex",flexWrap:"wrap"}} className='row'>

      <New data={data} setData={setData}/>
      </div>
   
    </div>
 
  </div>
</section>

  )
}

export default Bannerthree