import React, {  useState } from 'react'
import  {  useEffect } from 'react'
import Layout from '../Components/layout/Layout'
import HeroSection from '../Components/HeroSection'
import Loading from '../Components/loader/Loading'
const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  const timer = setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return () => clearTimeout(timer);
}, []);

  return (

    <div>
    {isLoading ? (
      <Loading />
    ) : (
      <Layout>
        <HeroSection />
      </Layout>
    )}
  </div>
)


  
}

export default Home