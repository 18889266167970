import React from "react";
import Layout from "../Components/layout/Layout";
import AboutSection from "../Components/about/AboutSection";

const About = () => {
  return (
    <Layout>
      <div id="wrapper">
        <AboutSection />
      </div>
    </Layout>
  );
};

export default About;
