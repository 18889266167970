/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../auth/Baseurl";
import axios from "axios";
import { ThemeContext } from "../../context/ContextFile";
import DatePicker from "react-datepicker";
const SearchLocation = () => {
  const [handle,setHandle]=useState()
  console.log(handle,"get");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let { SetTheme } = useContext(ThemeContext);
  const [query, setQuery] = useState("");

  const [quantity, setQuantity] = useState();

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (e.key === "ArrowDown") {
      setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
    }
  };
  console.log(quantity, "num");
  const formData = new FormData();

  formData.append("byAddress", query);
  formData.append("byNoOfGuest", quantity);
  if (startDate) {
    const formattedArrivalDate = startDate.toISOString().split("T")[0];
    formData.append("byCheckin", formattedArrivalDate);
  }

  // Departure Date
  if (endDate) {
    const formattedDepartureDate = endDate.toISOString().split("T")[0];
    formData.append("byCheckout", formattedDepartureDate);
  }

  const isFormValid = () => {
    return quantity || query || startDate || endDate;
  };

  const GetData = () => {
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("quantity", quantity);
    formData.append("query", query);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("byListingType", handle);

    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        SetTheme(err);
        console.log(err);
      });
  };

  const autoCompleteRef = useRef(null);
  let autoComplete;

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject?.formatted_address;
    updateQuery(query);
    console.log({ query });
    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };
    // console.log({ latLng });
    // setSelectedLocation(latLng);
  };

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, [setQuery, autoCompleteRef]);

  return (
    <div
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
      className="hero-map-search-wrap fl-wrap"
    >
      <div
        style={{ width: "85%", justifyContent: "center", alignItems: "center" }}
        className="hero-search  fl-wrap"
      >
        <div className="main-search-input-wrap fl-wrap">
          <div className="main-search-input fl-wrap">
            <div className="main-search-input-item">
              <label>{/* <i className="fal fa-user-friends" /> */}</label>
              <input
                type="number"
                name="quantity"
                placeholder="Persons"
                value={quantity}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="main-search-input-item location autocomplete-container">
              <label>{/* <i className="fal fa-map-marker-check" /> */}</label>
              <input
                type="text"
                ref={autoCompleteRef}
                placeholder="Location"
                className="autocomplete-input pac-target-input"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
              <Link href="#">
                <i className="fa fa-dot-circle-o" />
              </Link>
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-evenly" }}
              className=""
            >
              <div className="main-search-input-item location autocomplete-container">
                {/* <p className="opening-hours-day"> Arrival Date</p> */}
                <DatePicker
                  // style={{paddingLeft:"0px"}}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(null);
                  }}
                  placeholderText="Arrival Date"
                />
              </div>
              <div className="main-search-input-item location autocomplete-container">
                {/* <p className="opening-hours-day">Departure Date</p> */}
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Departure Date"
                  minDate={startDate}
                />
              </div>
              <div className="">
                <select
                  data-placeholder="Apartments"
                  className="nice-select chosen-select no-search-select"
                  // value={service.type}
                  
                  onChange={(e) =>
                    setHandle(e.target.value)
                  }
                >
                  <option style={{textAlign:"left"}}>Listing Type</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Beachfront">Beachfront</option>
                  <option value="Bed & Breakfast">Bed & Breakfast</option>
                  <option value="Cabin">Cabin</option>
                  <option value="Loft">Loft</option>
                </select>
              </div>
            </div>

            <button
              className="main-search-button color2-bg"
              onClick={GetData}
              disabled={!isFormValid()}
            >
              Search <i className="far fa-search" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLocation;
