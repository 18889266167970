/* eslint-disable */
import React, { useEffect, useState } from "react";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./loader/Loading"; // Import Loader component
import { toast } from "react-toastify";

const List = () => {
  const [loading, setLoading] = useState(true); // Set initial loading state to true
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [cardsPerPage] = useState(3); // Number of cards per page

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/listing`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setData(response.data?.data);
      setLoading(false); // Set loading state to false when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/listing/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // setLoading(true)
      toast.success("successfully deleted");
      fetchData();
    } catch (error) {
      setLoading(false);
    }
  };

  // Logic to get current cards
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="col-md-9">
          <div className="dashboard-title   fl-wrap">
            <h3>Your Listings</h3>
          </div>

          {currentCards.map((e) => {
            return (
              <div className="dashboard-list-box fl-wrap" key={e.id}>
                <div className="dashboard-list fl-wrap">
                  <div className="dashboard-message">
                    <div className="booking-list-contr">
                      <Link
                        to={`/EditListing/${e.id}`}
                        className="color-bg tolt"
                        data-microtip-position="left"
                        data-tooltip="Edit"
                      >
                        <i className="fal fa-edit" />
                      </Link>
                      <Link
                        to="#"
                        className="red-bg tolt"
                        data-microtip-position="left"
                        data-tooltip="Delete"
                        onClick={() => handleDelete(e.id)}
                      >
                        <i className="fal fa-trash" />
                      </Link>
                    </div>
                    <div className="dashboard-message-text">
                      <img src={e?.listing_images[0].image} alt="" />
                      <h4>
                        <Link to={`/details/${e.id}`}>{e.title}</Link>
                      </h4>
                      <div className="geodir-category-location clearfix">
                        <Link to="#">{e.address}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="pagination">
            <Link
              to="#"
              className="prevposts-link"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fas fa-caret-left" />
              <span>Prev</span>
            </Link>
            {Array.from({ length: Math.ceil(data.length / cardsPerPage) }).map(
              (item, index) => (
                <Link
                  key={index}
                  to="#"
                  className={
                    currentPage === index + 1 ? "current-page" : undefined
                  }
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Link>
              )
            )}
            <Link
              to="#"
              className="nextposts-link"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(data.length / cardsPerPage)}
            >
              <span>Next</span>
              <i className="fas fa-caret-right" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default List;
