import React from 'react'
import Layout from '../Components/layout/Layout'
import BannerDashboard from '../Components/BannerDashboard'
import Sidebar from '../Components/Sidebar'
import Edit from '../Components/listingedit/EditListing'

const EditListing = () => {
  return (
    <Layout>
    <div id="wrapper">
      {/* content*/}
      <div className="content">
        {/*  section  */}

        <BannerDashboard />
        {/*  section  end*/}
        {/*  section  */}
        <section className="gray-bg main-dashboard-sec" id="sec1">
          <div className="container">
            {/*  dashboard-menu*/}
            <Sidebar />
            {/* dashboard-menu  end*/}
            {/* dashboard content*/}
            <Edit/>
            {/* dashboard content end*/}
          </div>
        </section>
        {/*  section  end*/}
        <div className="limit-box fl-wrap" />
      </div>
      {/*content end*/}
    </div>
  </Layout>
  )
}

export default EditListing