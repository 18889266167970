import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="main-footer fl-wrap">
    {/* footer-header*/}
    <div className="footer-header fl-wrap grad ient-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="subscribe-header">
              <h3>
                Subscribe For a <span>Newsletter</span>
              </h3>
              <p>Whant to be notified about new locations ? Just sign up.</p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="subscribe-widget">
              <div className="subcribe-form">
                <form id="subscribe">
                  <input
                    className="enteremail fl-wrap"
                    name="email"
                    id="subscribe-email"
                    placeholder="Enter Your Email"
                    spellCheck="false"
                    type="text"
                  />
                  <button
                    type="submit"
                    id="subscribe-button"
                    className="subscribe-button"
                  >
                    <i className="fal fa-envelope" />
                  </button>
                  <label
                    htmlFor="subscribe-email"
                    className="subscribe-message"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* footer-header end*/}
    {/*footer-inner*/}
    <div className="footer-inner   fl-wrap">
      <div className="container">
        <div className="row">
          {/* footer-widget*/}
          <div className="col-md-4">
            <div className="footer-widget fl-wrap">
              <div className="footer-logo">
                <Link  to="/">
                  <img style={{width:"100px",height:"100px"}} src="/image/logo.png" alt="" />
                </Link> 
              </div>
              <div className="footer-contacts-widget fl-wrap">
                <p>
                  In ut odio libero, at vulputate urna. Nulla tristique mi a massa
                  convallis cursus. Nulla eu mi magna. Etiam suscipit commodo
                  gravida.{" "}
                </p>
                <ul className="footer-contacts fl-wrap no-list-style">
                  <li>
                    <span>
                      <i className="fal fa-envelope" /> Mail :
                    </span>
                    <Link  to="#" >
                      yourmail@domain.com
                    </Link> 
                  </li>
                  <li>
                    {" "}
                    <span>
                      <i className="fal fa-map-marker" /> Adress :
                    </span>
                    <Link  to="#" >
                      USA 27TH Brooklyn NY
                    </Link> 
                  </li>
                  <li>
                    <span>
                      <i className="fal fa-phone" /> Phone :
                    </span>
                    <Link  to="#">+7(111)123456789</Link> 
                  </li>
                </ul>
                <div className="footer-social">
                  <span>Find us on: </span>
                  <ul className="no-list-style">
                    <li>
                      <Link  to="#" >
                        <i className="fab fa-facebook-f" />
                      </Link> 
                    </li>
                    <li>
                      <Link  to="#" >
                        <i className="fab fa-twitter" />
                      </Link> 
                    </li>
                    <li>
                      <Link  to="#" >
                        <i className="fab fa-instagram" />
                      </Link> 
                    </li>
                    <li>
                      <Link  to="#" >
                        <i className="fab fa-vk" />
                      </Link> 
                    </li>
                    <li>
                      <Link  to="#" >
                        <i className="fab fa-whatsapp" />
                      </Link> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* footer-widget end*/}
          {/* footer-widget*/}
          <div className="col-md-4">
            <div className="footer-widget fl-wrap">
              <h3>Our Last News</h3>
              <div className="footer-widget-posts fl-wrap">
                <ul className="no-list-style">
                  <li className="clearfix">
                    <Link  to="#" className="widget-posts-img">
                      <img src="images/all/4.jpg" className="respimg" alt="" />
                    </Link> 
                    <div className="widget-posts-descr">
                      <Link  to="#" title="">
                        Vivamus dapibus rutrum
                      </Link> 
                      <span className="widget-posts-date">
                        <i className="fal fa-calendar" /> 21 Mar 09.05{" "}
                      </span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <Link  to="#" className="widget-posts-img">
                      <img src="images/all/2.jpg" className="respimg" alt="" />
                    </Link> 
                    <div className="widget-posts-descr">
                      <Link  to="#" title="">
                        {" "}
                        In hac habitasse platea
                      </Link> 
                      <span className="widget-posts-date">
                        <i className="fal fa-calendar" /> 7 Mar 18.21{" "}
                      </span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <Link  to="#" className="widget-posts-img">
                      <img src="images/all/7.jpg" className="respimg" alt="" />
                    </Link> 
                    <div className="widget-posts-descr">
                      <Link  to="#" title="">
                        Tortor tempor in porta
                      </Link> 
                      <span className="widget-posts-date">
                        <i className="fal fa-calendar" /> 7 Mar 16.42{" "}
                      </span>
                    </div>
                  </li>
                </ul>
                <Link  to="#" className="footer-link">
                  Read all <i className="fal fa-long-arrow-right" />
                </Link> 
              </div>
            </div>
          </div>
          {/* footer-widget end*/}
          {/* footer-widget  */}
          <div className="col-md-4">
            <div className="footer-widget fl-wrap ">
              <h3>Helpful links</h3>
              <ul className="footer-list fl-wrap">
                <li>
                  <Link  to="">About Our Company</Link> 
                </li>
                <li>
                  <Link  to="">Our last News</Link> 
                </li>
                <li>
                  <Link  to="">Pricing Plans</Link> 
                </li>
                <li>
                  <Link  to="">Help Center</Link> 
                </li>
              </ul>
              <Link  to="#" className=" down-btn color3-bg fl-wrap">
                <i className="fab fa-apple" /> Apple Store{" "}
              </Link> 
              <Link  to="#" className=" down-btn color3-bg fl-wrap">
                <i className="fab fa-android" /> Google Play{" "}
              </Link> 
            </div>
          </div>
          {/* footer-widget end*/}
        </div>
      </div>
      {/* footer bg*/}
      <div className="footer-bg" data-ran={4} />
      <div className="footer-wave">
        <svg viewBox="0 0 100 25">
          <path fill="#fff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z" />
        </svg>
      </div>
      {/* footer bg  end*/}
    </div>
    {/*footer-inner end */}
    {/*sub-footer*/}
    <div className="sub-footer  fl-wrap">
      <div className="container">
        <div className="copyright"> © FLY_inn 2024 . All rights reserved.</div>
        <div className="lang-wrap">
          <div className="show-lang">
            <span>
              <i className="fal fa-globe-europe" />
              <strong>En</strong>
            </span>
            <i className="fa fa-caret-down arrlan" />
          </div>
          <ul className="lang-tooltip lang-action no-list-style">
            <li>
              <Link  to="#" className="current-lan" data-lantext="En">
                English
              </Link> 
            </li>
            <li>
              <Link  to="#" data-lantext="Fr">
                Franais
              </Link> 
            </li>
            <li>
              <Link  to="#" data-lantext="Es">
                Espaol
              </Link> 
            </li>
            <li>
              <Link  to="#" data-lantext="De">
                Deutsch
              </Link> 
            </li>
          </ul>
        </div>
        <div className="subfooter-nav">
          <ul className="no-list-style">
            <li>
              <Link  to="#">Terms of use</Link> 
            </li>
            <li>
              <Link  to="#">Privacy Policy</Link> 
            </li>
            <li>
              <Link  to="#">Blog</Link> 
            </li>
          </ul>
        </div>
      </div>
    </div>
    {/*sub-footer end */}
  </footer>
  
  )
}

export default Footer