import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Listen from "./Pages/Listen";
import Booking from "./Pages/Booking";
import Profile from "./Pages/Profile";
import Changepassword from "./Pages/Changepassword";
import Details from "./Pages/Details";
import MyListing from "./Pages/MyListing";
import EditListing from "./Pages/EditListing";
import ThemProvider from "./context/ContextFile";
import Favorite from "./Pages/Favorite";
import Messages from "./Pages/Message";
import About from "./Pages/About";

function App({ children }) {
  return (
    <>
      <ThemProvider>
        {children}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Listen />} />
            <Route path="/about" element={<About />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/my_listing" element={<MyListing />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/details/:id" element={<Details />} />
            <Route path="/EditListing/:id" element={<EditListing />} />

            <Route path="/changepassword" element={<Changepassword />} />
          </Routes>
        </BrowserRouter>
      </ThemProvider>
    </>
  );
}

export default App;
