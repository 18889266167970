import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "./auth/Baseurl";

function FavoriteR() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(2);   

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/listing-favourite`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log(response,"getfav");
        setData(response.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); 
  }, []); 

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="col-md-9">
      <div className="dashboard-title   fl-wrap">
        <h3>All Favorites</h3>
      </div>
      {currentCards?.map((e) => (
        <div className="profile-edit-container fl-wrap block_box">
          {/* booking-list*/}
          <div className="booking-list">
            <div className="booking-list-message">
              <div className="booking-list-message-avatar">
                <img src={e?.host?.image} alt="" />
              </div>
             
              <div className="booking-list-message-text">
                <Link to={`/details/${e.id}`}>


                <h4>
                {e?.title}
                </h4>
                </Link>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Address :{e?.country} </span>
                  <span className="booking-text">
                    <Link to="#">
                     -  {e?.address}
                    </Link>
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Description :</span>
                  <span className="booking-text">{e?.description}</span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">additional Guest :</span>
                  <span className="booking-text">
                    ${e?.addi_guest}
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Additional Guest Price :</span>
                  <span className="booking-text">
                    <Link to="#" target="_top">
                      ${e?.addi_guest_price}
                    </Link>
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">children_allowed :</span>
                  <span className="booking-text">
                    <Link to="#" >
                      {e?.children_allowed}
                    </Link>
                  </span>
                </div>
                <div className="booking-details fl-wrap">
                  <span className="booking-title">Max Day Booking :</span>
                  <span className="booking-text">
                    {e?.max_day_booking} to {e?.min_day_booking} days
                   
                  </span>
                </div>
                <span className="fw-separator" />
                <h4>Prices</h4>
                <p>Nightly : ${e?.nightly}</p>
                <p>Weekend Price : ${e?.weekend}</p>
                <p>Weekly seven plus price : ${e?.weekly_seven_plus}</p>
                <p>Weekly thirty plus price : ${e?.weekly_thirty_plus}</p>
                <p>Tax : ${e?.tax}</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="pagination">
        <Link
          to="#"
          className="prevposts-link"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fas fa-caret-left" />
          <span>Prev</span>
        </Link>
        {Array.from({ length: Math.ceil(data.length / cardsPerPage) }).map(
          (item, index) => (
            <Link
              key={index}
              to="#"
              className={currentPage === index + 1 ? "current-page" : undefined}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Link>
          )
        )}
        <Link
          to="#"
          className="nextposts-link"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(data.length / cardsPerPage)}
        >
          <span>Next</span>
          <i className="fas fa-caret-right" />
        </Link>
      </div>
    </div>
  );
}

export default FavoriteR;
