/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "swiper/css";
import "swiper/css/pagination";
import DatePicker from "react-datepicker";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import "react-datepicker/dist/react-datepicker.css";
import { Pagination } from "swiper/modules";
import Layout from "../Components/layout/Layout";
import StripeCheckout from "react-stripe-checkout";
import Swal from "sweetalert2";
import BtnLoader from "../Components/BtnLoader/BtnLoader";

const Details = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [get, setGet] = useState([]);
  const [getone, setGetone] = useState([]);
  const [data, setData] = useState();
  const { id } = useParams();
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/listing-user-get-by-id/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data, ">>>>>>>>>>>>>");
      // setQuantity(response.data?.listing);
      setData(response.data?.listing);
      setGet(eval(JSON.parse(response.data?.listing?.facilities)));
      setGetone(eval(JSON.parse(response.data?.listing?.amenities)));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(data, "data");

  // console.log(totalstripe,"??");
  // console.log(startDate, "values");
  // amenities and facilities api end //
  // stripe start //

  const onToken = async (token, note) => {
    try {
      // console.log(token, "token");
      const formData = new FormData();
      // console.log(noteValue.note, "note");
      if (data?.instant_booking === 1) {
        formData.append(
          "payment_method",
          data?.instant_booking === 1 ? "stripe" : "manually"
        );
        formData.append("no_of_guest", addiGuest);
        formData.append("note", text);
        formData.append("listing_id", data.id);
        formData.append("token", token.id);
        formData.append("first_name", userData.first_name);
        formData.append("last_name", userData1.last_name);
        formData.append("phone", userData2.contact_phone);
        formData.append("total", totalAmount);
      }else{
        formData.append(
          "payment_method",
          data?.instant_booking === 1 ? "stripe" : "manually"
        );
        formData.append("no_of_guest", addiGuest);
        formData.append("note", text);
        formData.append("listing_id", data.id);
        formData.append("total", totalAmount);
      }

      // Arrival Date
      if (startDate) {
        const formattedArrivalDate = startDate.toISOString().split("T")[0];
        formData.append("arrive_date", formattedArrivalDate);
      }

      // Departure Date
      if (endDate) {
        const formattedDepartureDate = endDate.toISOString().split("T")[0];
        formData.append("depart_date", formattedDepartureDate);
      }

      const response = await axios.post(`${BASE_URL}/booking`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setLoader(false);

      // console.log("Booking successful!", response.data);
      if (data?.instant_booking === 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Booking successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/");
      } else {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Booking created Successfull",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error submitting booking:",
      });
    }
  };

  const [addiGuest, setAddiGuest] = useState(data?.addi_guest || 0);
  const [extraServices, setExtraServices] = useState({});

  useEffect(() => {
    const initialServices = {};
    data?.listing_extra_service_prices?.forEach((service) => {
      initialServices[service.id] = false;
    });
    setExtraServices(initialServices);
  }, []);

  const handleIncrement = () => {
    setAddiGuest((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setAddiGuest((prev) => Math.max(prev - 1, 0));
  };

  const handleServiceChange = (id) => {
    setExtraServices((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const rates = {
    nightly: data?.nightly,
    weekend: data?.weekend,
    weekly_seven_plus: data?.weekly_seven_plus,
    weekly_thirty_plus: data?.weekly_thirty_plus,
    city_fee: data?.city_fee,
    clean_fee: data?.clean_fee,
    security_deposit: data?.security_deposit,
    tax: data?.tax,
    addi_guest_price: data?.addi_guest_price,
  };

  const calculateTotalAmount = (startDate, endDate, rates) => {
    const arrivalDate = new Date(startDate);
    const departureDate = new Date(endDate);

    const totalDays =
      Math.ceil((departureDate - arrivalDate) / (1000 * 60 * 60 * 24)) + 1;

    let weekdayCount = 0;
    let weekendCount = 0;

    for (let i = 0; i < totalDays; i++) {
      const currentDate = new Date(arrivalDate);
      currentDate.setDate(arrivalDate.getDate() + i);
      const currentDay = currentDate.getDay();

      if (currentDay >= 1 && currentDay <= 5) {
        weekdayCount++;
      } else if (currentDay === 0 || currentDay === 6) {
        weekendCount++;
      }
    }

    let totalAmount = 0;

    if (totalDays > 30) {
      totalAmount =
        weekdayCount * rates.weekly_thirty_plus + weekendCount * rates.weekend;
    } else if (totalDays > 7) {
      totalAmount =
        weekdayCount * rates.weekly_seven_plus + weekendCount * rates.weekend;
    } else {
      totalAmount = weekdayCount * rates.nightly + weekendCount * rates.weekend;
    }

    // Additional fees
    const additionalFees =
      rates.city_fee + rates.clean_fee + rates.security_deposit + rates.tax;

    // Calculate the total amount including additional guest price
    totalAmount += addiGuest * rates.addi_guest_price;

    // Add extra services cost if addiGuest is greater than 0
    if (addiGuest > 0) {
      const extraServiceCost = data.listing_extra_service_prices.reduce(
        (sum, service) => {
          return extraServices[service.id] ? sum + service.price : sum;
        },
        0
      );
      totalAmount += extraServiceCost;
    }

    if (totalAmount) {
      return totalAmount + additionalFees;
    } else {
      return 0;
    }
  };

  const totalAmount = calculateTotalAmount(startDate, endDate, rates);

  console.log(totalAmount, "valuetotal");

  // end  stripe total amount  calculate

  // console.log(quantity, "valueGet");

  const [userData, setUserData] = useState({});
  const [userData1, setUserData1] = useState({});
  const [userData2, setUserData2] = useState({});

  useEffect(() => {
    const retrievedData = JSON.parse(localStorage.getItem("data"));
    // console.log(retrievedData, "name");
    if (retrievedData) {
      setUserData(retrievedData);
    }
    if (retrievedData) {
      setUserData1(retrievedData);
    }
    if (retrievedData) {
      setUserData2(retrievedData);
    }
  }, []);
  const [text, setText] = useState("");
  const handleValue = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const defaultCenter = { lat: 0, lng: 0 };
  const center = {
    lat: Number(data?.lat) || defaultCenter.lat,
    lng: Number(data?.lng) || defaultCenter.lng,
  };

  const validationSchema = Yup.object().shape({
    arrive_date: Yup.date().required("Required"),
    depart_date: Yup.date()
      .min(Yup.ref("arrive_date"), "Departure date must be after arrival date")
      .required("Required"),
    note: Yup.string(),
  });
  // console.log(center.lat, "lat");
  return (
    <Layout>
      <div id="wrapper">
        <div className="content">
          <section
            className="listing-hero-section hidden-section"
            data-scrollax-parent="true"
            id="sec1"
          >
            {data?.listing_images?.[0] && (
              <div className="bg-parallax-wrap">
                <div
                  className="bg par-elem "
                  // data-bg="images/bg/6.jpg"
                  data-scrollax="properties: { translateY: '30%' }"
                  style={{
                    backgroundImage: `url(${data.listing_images[0]?.image})`,
                    transform: "translateZ(0px) translateY(-3.24763%)",
                  }}
                />
                <div className="overlay" />
              </div>
            )}

            <div className="container">
              <div className="list-single-header-item  fl-wrap">
                <div className="row">
                  <div className="col-md-9">
                    <h1>
                      {data?.title}
                      <span className="verified-badge">
                        <i className="fal fa-check" />
                      </span>
                    </h1>
                    <div className="geodir-category-location fl-wrap">
                      <Link to="#">
                        <i className="fas fa-map-marker-alt" /> {data?.area}
                      </Link>{" "}
                      <Link to="#">
                        <i className="fal fa-envelope" /> {data?.host?.email}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list-single-header_bottom fl-wrap">
                <div className="list-single-author">
                  {" "}
                  <Link to="#">
                    <span className="author_avatar">
                      {" "}
                      <img alt="" src={data?.host?.image} />
                    </span>
                    {data?.host?.first_name}
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/* scroll-nav-wrapper*/}

          <div
            style={{
              display: "none",
              width: 1583,
              height: 65,
              float: "none",
            }}
          />
          <section className="gray-bg no-top-padding">
            <div className="container">
              <div className="clearfix" />
              <div className="row">
                <div className="col-md-8">
                  <div className="list-single-main-wrapper fl-wrap" id="sec2">
                    <div className="list-single-main-item fl-wrap block_box">
                      <div className="list-single-main-item-title">
                        <h3>Description</h3>
                      </div>
                      <div className="list-single-main-item_content fl-wrap">
                        <p>{data?.description}</p>
                      </div>
                    </div>
                    {/* list-single-main-item end */}
                    {/* list-single-main-item */}
                    <div className="list-single-main-item fl-wrap block_box">
                      <div className="list-single-main-item-title">
                        <h3>Amenities</h3>
                      </div>
                      <div className="list-single-main-item_content fl-wrap">
                        <div className="listing-features fl-wrap">
                          <ul className="no-list-style">
                            {Array.isArray(getone) &&
                              getone.map((amenity, index) => (
                                <li key={index}>
                                  <Link to="#">
                                    <i className="fa fa-rocket" /> {amenity}
                                    Text
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="list-single-main-item fl-wrap block_box">
                      <div className="list-single-main-item-title">
                        <h3>Facilities</h3>
                      </div>
                      <div className="list-single-main-item_content fl-wrap">
                        <div className="listing-features fl-wrap">
                          <ul className="no-list-style">
                            {Array.isArray(get) &&
                              get.map((amenity, index) => (
                                <li key={index}>
                                  <Link to="#">
                                    <i className="fa fa-rocket" /> {amenity}
                                    Text
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* list-single-main-item end */}
                    {/* list-single-main-item*/}
                    <div
                      className="list-single-main-item fl-wrap block_box"
                      id="sec3"
                    >
                      <div className="list-single-main-item-title">
                        <h3>Gallery / Photos</h3>
                      </div>
                      <div className="list-single-main-item_content fl-wrap">
                        <div className="single-carousel-wrap fl-wrap lightgallery">
                          <div
                            className="sc-next sc-btn color2-bg"
                            tabIndex={0}
                            role="button"
                            aria-label="Next slide"
                            aria-disabled="false"
                          >
                            <i className="fas fa-caret-right" />
                          </div>
                          <div
                            className="sc-prev sc-btn color2-bg swiper-button-disabled"
                            tabIndex={0}
                            role="button"
                            aria-label="Previous slide"
                            aria-disabled="true"
                          >
                            <i className="fas fa-caret-left" />
                          </div>
                          <div className="single-carousel fl-wrap full-height">
                            <div
                              className="swiper-container swiper-container-horizontal swiper-container-free-mode"
                              style={{ cursor: "grab" }}
                            >
                              {/* swiper-slide*/}
                              <Swiper
                                slidesPerView={3}
                                spaceBetween={20}
                                pagination={{
                                  clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                              >
                                {data?.listing_images.map((slide) => (
                                  <SwiperSlide key={slide.id}>
                                    <div
                                      className="swiper-slide"
                                      // style={{ marginRight: 10 }}
                                    >
                                      <div className="box-item">
                                        <img src={slide.image} alt="" />{" "}
                                        {/* Assuming the image URL is in slide.image */}
                                        <Link
                                          to={slide.link}
                                          className="gal-link popup-image"
                                        >
                                          <i className="fa fa-search" />
                                        </Link>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-widget-item fl-wrap block_box">
                      <div className="box-widget-item-header">
                        <h3> Price Range </h3>
                      </div>
                      <div className="box-widget">
                        <div className="box-widget-content">
                          <div className="claim-price-wdget fl-wrap">
                            <div className="claim-price-wdget-content fl-wrap">
                              <div className="pricerange fl-wrap">
                                <span>Additional Guest Price : </span>
                                {data?.addi_guest_price}
                              </div>
                              <div className="pricerange fl-wrap">
                                <span>City Fee : </span>
                                {data?.city_fee}
                              </div>
                              <div className="pricerange fl-wrap">
                                <span>City Fee Daily: </span>
                                {data?.city_fee_daily}
                              </div>
                              <div className="pricerange fl-wrap">
                                <span>City Fee Per stay: </span>
                                {data?.city_fee_per_stay}
                              </div>
                              <div className="pricerange fl-wrap">
                                <span>Clean Fee: </span>
                                {data?.clean_fee}
                              </div>
                              <div className="pricerange fl-wrap">
                                <span>Clean Fee Daily: </span>
                                {data?.clean_fee_daily}
                              </div>
                              <div className="pricerange fl-wrap">
                                <span>Clean Fee Per stay: </span>
                                {data?.clean_fee_per_stay}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* list-single-main-item end */}
                  </div>
                </div>
                {/* list-single-main-wrapper-col end */}
                {/* list-single-sidebar */}
                <div className="col-md-4">
                  {/*box-widget-item */}

                  <div className="box-widget-item fl-wrap block_box">
                    <div className="box-widget-item-header">
                      <h3>Book a Room</h3>
                    </div>
                    <div className="box-widget">
                      <div className="box-widget-content">
                      <Formik 
                        
                        initialValues={{
                          arrive_date: null,
                          depart_date: null,
                          note: "",
                        }}
                        validationSchema={Yup.object().shape({
                          arrive_date: Yup.date().required("Required"),
                          depart_date: Yup.date()
                            .min(
                              Yup.ref("arrive_date"),
                              "Departure date must be after arrival date"
                            )
                            .required("Required"),
                          note: Yup.string(),
                        })}
                      >
                        {({ isSubmitting }) => (
                          <Form className="add-comment custom-form">
                            <fieldset>
                              <div className="date-picker-container ">
                                <DatePicker
                                  className="react-datepicker-wrapper"
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                    setEndDate(null);
                                  }}
                                  placeholderText="Arrival Date"
                                />

                                <DatePicker
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  placeholderText="Departure Date"
                                  minDate={startDate}
                                />
                              </div>
                              <div className="quantity fl-wrap">
                                <span>
                                  <i className="fal fa-user-plus"></i>Guest :{" "}
                                </span>
                                <div className="quantity-item">
                                  <input
                                    type="button"
                                    value="-"
                                    className="minus"
                                    onClick={handleDecrement}
                                  />
                                  <input
                                    type="text"
                                    name="addiGuest"
                                    title="Qty"
                                    className="qty color-bg"
                                    data-min="1"
                                    data-max="3"
                                    data-step="1"
                                    value={addiGuest}
                                    readOnly
                                  />
                                  <input
                                    type="button"
                                    onClick={handleIncrement}
                                    value="+"
                                    className="plus"
                                  />
                                </div>
                              </div>
                              <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Extra Services</h3>
                              </div>
                              <div className="custom-form">
                                <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                  {data?.listing_extra_service_prices?.map(
                                    (service) => (
                                      <li key={service.id}>
                                        <input
                                          id={service.id}
                                          type="checkbox"
                                          name={service.name}
                                          checked={extraServices[service.id]}
                                          onChange={() =>
                                            handleServiceChange(service.id)
                                          }
                                        />
                                        <label htmlFor={service.id}>
                                          {service.name}
                                        </label>
                                        <label
                                          style={{
                                            textAlign: "end",
                                            display: "flex",
                                            width: "150px",
                                            justifyContent: "end",
                                          }}
                                          htmlFor={service.id}
                                        >
                                          {service.price}
                                        </label>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                              <textarea
                                as="textarea"
                                name="note"
                                cols="40"
                                onChange={handleValue}
                                rows="3"
                                placeholder="Additional Information:"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                className="box-widget-item-header"
                              >
                                <h3>Total</h3>
                                <p>${totalAmount}</p>
                              </div>
                              {
                               data?.instant_booking === 1 ?
                              <StripeCheckout
                                token={(token) => onToken(token)}
                                stripeKey="pk_test_51OM5QgHIC6YSoZmKJYiuVcSsPM6QHO7iVNjQi5t0mSudvWzgSdos55tgQyTOmaFrEqdzHYBYpf7SB6aMGahsfVkn00xlfczKqR"
                              >
                                <button
                                  type="submit"
                                  className="btn color2-bg url_btn float-btn"
                                  disabled={isSubmitting}
                                >
                                  {loader ? (
                                    <BtnLoader />
                                  ) : (
                                    <>
                                      {isSubmitting
                                        ? "Submitting..."
                                        : "Payment Now"}
                                      <i className="fal fa-bookmark" />
                                    </>
                                  )}
                                </button>
                              </StripeCheckout>
                              : 
                              <button
                              type="submit"
                              className="btn color2-bg url_btn float-btn"
                              disabled={isSubmitting}
                              onClick={onToken}
                            >
                              {loader ? (
                                <BtnLoader />
                              ) : (
                                <>
                                  {isSubmitting
                                  
                                    ? "Submitting..."
                                    : "Requesting for payment"}
                                  <i className="fal fa-bookmark" />
                                </>
                              )}
                            </button>
                              }
                            </fieldset>
                          </Form>
                        )}
                      </Formik>
                      </div>
                    </div>
                  </div>

                  <div className="box-widget-item fl-wrap block_box">
                    <div className="box-widget-item-header">
                      <h3>Location</h3>
                    </div>
                    <div className="box-widget">
                      <div className="map-container">
                        <div>
                          <div style={{ height: "300px", width: "100%" }}>
                            {data?.lat && data?.lng && (
                              <GoogleMap
                                mapContainerStyle={{
                                  height: "300px",
                                  // width: "100%",
                                }}
                                center={center}
                                zoom={10}
                                apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
                              >
                                <MarkerF position={center} />
                              </GoogleMap>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="box-widget-content bwc-nopad">
                        <div className="list-author-widget-contacts list-item-widget-contacts bwc-padside">
                          <ul className="no-list-style">
                            <li>
                              <span>
                                <i className="fal fa-map-marker" /> Adress :
                              </span>{" "}
                              <Link to="#">{data?.address}</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="list-widget-social bottom-bcw-box  fl-wrap">
                          <ul className="no-list-style">
                            <li>
                              <Link to="#">
                                <i className="fab fa-facebook-f" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="fab fa-twitter" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="fab fa-vk" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="fab fa-instagram" />
                              </Link>
                            </li>
                          </ul>
                          <div className="bottom-bcw-box_link">
                            <Link
                              to="#"
                              className="show-single-contactform tolt"
                              data-microtip-position="top"
                              data-tooltip="Write Message"
                            >
                              <i className="fal fa-envelope" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="limit-box fl-wrap" />
        </div>
      </div>
    </Layout>
  );
};

export default Details;
