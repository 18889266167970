import React, { useState } from "react";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import SearchLocation from "../search/SearchLocation";
import { Link } from "react-router-dom";
// import MarkerLabel from "../MarkerLabel";

const LocationMap = ({ data }) => {
  // const { id } = useParams();
  const [selectedMarker, setSelectedMarker] = useState(null);
  if (!data || data?.length === 0) return null;

  const markers = data.map((item, index) => ({
    position: { lat: Number(item.lat), lng: Number(item.lng) },
    key: index.toString(),
    ...item,
  }));
  console.log(markers, "points");

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <GoogleMap
        mapContainerStyle={{ height: "80vh", width: "100%" }}
        center={markers.length > 0 ? markers[0].position : { lat: 0, lng: 0 }}
        zoom={10}
        apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
      >
        {markers.map(
          (marker, index) =>
            // Validate marker position before rendering
            marker.position && (
              <MarkerF
                key={index}
                position={marker.position}
                onClick={() => setSelectedMarker(marker)}
                icon={{
                  url:
                    "https://img.icons8.com/ios-filled/50/ffffff/marker.png",
                  scaledSize: new window.google.maps.Size(80, 50),
                }}
                label={{
                  text: `$${marker?.addi_guest_price.toString()}`,
                  color: "blue",
                  fontSize: "15px",
                  fontWeight: "bold",
                  // borderStyle:"1px solid black",

                  backgroundColor: "white",
                  borderRadius: "50px",
                 
                
                }}
                style={{zoom:5}}
              />
            )
        )}
        {selectedMarker && (
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className="map-box">
              <div className="map-popup-wrap">
                <div className="map-popup">
                  {/* <div className="infoBox-close" >
                    <i className="fal fa-times"  />
                  </div> */}
                  <Link
                    to={`/details/${selectedMarker?.id}`}
                    className="listing-img-content fl-wrap"
                  >
                    <div className="infobox-status open">
                      {selectedMarker?.description}
                    </div>
                    {selectedMarker?.listing_images?.[0] && (

                      <img src={selectedMarker?.listing_images[0]?.image} alt="" />
                    )}
                  
                  </Link>{" "}
                  <div className="listing-content">
                    <div className="listing-content-item fl-wrap">
                      <div className="map-popup-location-category gym-cat" />
                      <div className="listing-title fl-wrap">
                        <h4>
                          <Link to={`/details/${selectedMarker?.id}`}>
                            {selectedMarker?.title}
                          </Link>
                        </h4>
                        <div className="map-popup-location-info">
                          <i className="fas fa-map-marker-alt" />
                          {selectedMarker?.address}
                        </div>
                      </div>
                      <div className="map-popup-footer">
                        <Link
                          to={`/details/${selectedMarker?.id}`}
                          className="main-link"
                        >
                          Details <i className="fal fa-long-arrow-right" />
                        </Link>
                        <Link to="#" className="infowindow_wishlist-btn">
                          <i className="fal fa-heart" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>

      <SearchLocation />
    </div>
  );
};

export default LocationMap;
